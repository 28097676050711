<template>
	<div class="confirm-box" v-if="show" @click="show = false">
		<transition name="fade">
			<div class="box" v-if="boxshow" @click.stop="">
				<div class="title">
					<span>{{title}}</span>
					<img src="../assets/image/gb.png" @click="close">
				</div>
				<div class="tips">
					{{tips}}
				</div>
				<div class="btn" @click.stop="confirm">
					确认
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'confirmBox',
		data() {
			return {
				show: false,
				boxshow: false,
				tips: '',
				title: '',
				resolve: null,
				reject: null
			};
		},
		created() {
		},
		methods: {
			close() {
				this.reject('cancel');
				this.show = false;
			},
			confirm() {
				this.resolve('confirm');
				this.show = false;
			},
			isconfirm() {
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.confirm-box{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#000000,.4);
		z-index: 9999999;
		.box{
			width: 520px;
			// height: 240px;
			background: #FFFFFF;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			display: flex;
			flex-direction: column;
			align-items: center;
			.title{
				width: 100%;
				height: 47px;
				position: relative;
				line-height: 47px;
				border-bottom: 1px solid #F5F5F5;
				text-align: center;
				img{
					width: 12px;
					height: 12px;
					position: absolute;
					right: 60px;
					top: 50%;
					transform:translateY(-50%) ;
					cursor: pointer;
				}
			}
			.tips{
				margin: 50px 0 ;
			}
			.btn{
				width: 86px;
				height: 32px;
				background: #FFFFFF;
				border: 1px solid #DEDEDE;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #3D7EFF;
				line-height: 32px;
				text-align: center;
				margin-bottom: 30px;
				cursor: pointer;
			}
		}

		.fade-enter,.fade-leave-to{
			transform:translate(-50%,-50%) scale(.5);
		}
		.fade-enter-active,.fade-leave-active{
			transition: all .2s;
		}
		.fade-enter-to,.fade-leave{
			transform: translate(-50%,-50%) scale(1);
		}
	}
</style>
