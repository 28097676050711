import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [{
    path: '/',
    name: 'login',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'logins',
    component: () => import( /* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/index',
    name: 'index',
    redirect: 'client/list',
    component: () => import( /* webpackChunkName: "index" */ '../views/index.vue'),
    children: [{
        path: '/client',
        name: 'indexs',
        component: () => import( /* webpackChunkName: "index" */ '../views/client/index.vue'),
        children: [{
            path: 'list',
            name: 'list',
            component: () => import( /* webpackChunkName: "list" */ '../views/client/list.vue'),
          },
          {
            path: 'addClient',
            name: 'addClient',
            component: () => import('../views/clientMan/addClient.vue')
          },
          {
            path: 'setting',
            name: 'setting',
            component: () => import('../views/client/setting.vue')
          },
          {
            path: 'detail',
            name: 'detail',
            component: () => import('../views/client/detail.vue')
          }
        ]
      },
      {
        path: '/propur',
        name: 'propur',
        component: () => import('../views/propur/index.vue'),
        children: [{
            path: 'prolist',
            name: 'prolist',
            component: () => import( /* webpackChunkName: "list" */ '../views/propur/prolist.vue'),
          },
          {
            path: 'productions',
            name: 'productions',
            component: () => import('../views/propur/productions.vue')
          }
        ]
      },
      {
        path: '/proMan',
        name: 'proMan',
        component: () => import('../views/proMan/index.vue'),
        children: [{
            path: 'prolist',
            name: 'prolists',
            component: () => import( /* webpackChunkName: "list" */ '../views/proMan/prolist.vue'),
          },
          {
            path: 'addpro',
            name: 'addpro',
            component: () => import('../views/proMan/addpro.vue')
          }
        ]
      },
      {
        path: '/banMan',
        name: 'proMbanneran',
        component: () => import('../views/banMan/index.vue'),
        children: [{
            path: 'bannerlist',
            name: 'bannerlist',
            component: () => import( /* webpackChunkName: "list" */ '../views/banMan/bannerlist.vue'),
          },
          {
            path: 'addbanner',
            name: 'addbanner',
            component: () => import('../views/banMan/addbanner.vue')
          }
        ]
      },
      {
        path: '/bankingManagement',
        name: 'bankingNanagement',
        component: () => import('../views/bankingManagement/index.vue'),
        children: [{
          path: 'list',
          name: 'list',
          component: () => import( /* webpackChunkName: "bankingManagement" */ '../views/bankingManagement/list.vue')
        },
        {
          path: 'merchantList',
          name: 'merchantList',
          component: () => import('../views/bankingManagement/merchantList.vue')
        }]
      },
      {
        path: '/proceedsCode',
        name: 'proceedsCode',
        component: () => import('../views/proceedsCode/index.vue'),
        children: [{
          path: 'list',
          name: 'list',
          component: () => import( /* webpackChunkName: "proceedsCode" */ '../views/proceedsCode/list.vue')
        }]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
