import {
    Notification
  } from 'element-ui';
  
  /**
   * [notifySuccess 成功提示]
   * @param  {[String]} msg [提示内容]
   */
  export const notifySuccess = (msg) => {
    Notification({
      title: '成功',
      message: msg,
      type: 'success'
    });
  };
  
  /**
   * [notifyError 失败提示]
   * @param  {[String]} msg [提示内容]
   */
  
  export const notifyError = (msg) => {
    Notification({
      title: '错误',
      message: msg,
      type: 'error'
    });
  };
  
  export const formatDateTime = (inputTime) => {
    let date = new Date(inputTime);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? (`0${m}`) : m;
    let d = date.getDate();
    d = d < 10 ? (`0${d}`) : d;
    // let h = date.getHours();
    // h = h < 10 ? (`0${h}`) : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? (`0${minute}`) : minute;
    second = second < 10 ? (`0${second}`) : second;
    return `${y}-${m}-${d}`;
  };
  
  export const formatDateTimeHms = (inputTime) => {
    let date = new Date(inputTime);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? (`0${m}`) : m;
    let d = date.getDate();
    d = d < 10 ? (`0${d}`) : d;
    let h = date.getHours();
    h = h < 10 ? (`0${h}`) : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? (`0${minute}`) : minute;
    second = second < 10 ? (`0${second}`) : second;
    return `${y}-${m}-${d} ${h}:${minute}:${second}`;
  };
  
  
  export const formatDateTimeLine = (inputTime) => {
    let date = new Date(inputTime);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? (`0${m}`) : m;
    let d = date.getDate();
    d = d < 10 ? (`0${d}`) : d;
    // let h = date.getHours();
    // h = h < 10 ? (`0${h}`) : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? (`0${minute}`) : minute;
    second = second < 10 ? (`0${second}`) : second;
    return `${y}${m}${d}`;
  };
  /**
   * [missingNumber 计算整型数组中缺值]
   * @param  {[Array]} nums  [整型数组]
   * @return {[type]}      [description]
   */
  export const missingNumber = (nums) => {
    nums.sort((a, b) => {
      return a - b;
    });
    let n = 1;
    if (nums[0] != 1) {
      return 1;
    }
    for (let i = 0; i < nums.length; i++) {
      if (nums[i] + 1 != nums[i + 1]) {
        n = nums[i] + 1;
        break;
      }
    }
    return n;
  };
  
  export const changRouters = (routers) => {
    let roleId = (window.localStorage.getItem('loginInfo') && JSON.parse(window.localStorage.getItem('loginInfo')).role_id.split(',')) || [];
    let rous = [];
    routers.forEach((item)=>{
      if(roleId.includes(item.jurisdictionId)){
        rous.push(item)
      }
    })
    return rous;
  };
  