
import myButton from '@/components/myButton';
import pop from '@/components/pop'
import confirmbox from '@/components/confirm';
import Vue from 'vue';
import myInput from '@/components/my-input';
import navigationModel from '@/components/NavigationModel.vue';
var plugin = {};

plugin.install = function(Vue){
	Vue.component('myButton',myButton);
	Vue.component('pop',pop);
	Vue.component('myInput',myInput);
	Vue.component('navigationModel',navigationModel)
	Vue.prototype.$myconfirm = confirm;
}

const confirm = function (tips = '提示内容', title = '提示') {
	const dom = Vue.extend(confirmbox);
	const confirmDom = new dom();
		confirmDom.$mount();
    document.body.appendChild(confirmDom.$el);
	confirmDom.show = true;
	confirmDom.tips = tips;
	confirmDom.title = title;
	setTimeout(() => {
		confirmDom.boxshow = true;
	}, 10);
	return confirmDom.isconfirm()
	.then(res => {
		return Promise.resolve(res);
	})
	.catch(err => {
		return Promise.reject(err);
	});
};
export default plugin