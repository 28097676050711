import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/base.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import myplugin from '@/plugin/plugin';
import * as request from '@/plugin/request';
import * as plugin from '@/plugin/public'
import MuyuNavigationModel from 'muyu-navigation-model'
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(myplugin);
Vue.use(MuyuNavigationModel);
Vue.prototype.$request = request;
Vue.prototype.$plugin = plugin;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
