<template>
<div class="popbox" @mouseover="debounce" @click="emit" :class="disabled ? 'disabled' : ''" @mouseleave="debounceClose">
  <div class="popitem">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  props: {
    tips: {
      type: String,
      default: '提示'
    },
    accessId: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      close: false,
    };
  },
  methods: {
    debounce(e) {
      if (this.show) return false;
      this.mouseover(e);
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 200);
    },
    debounceClose() {
      if (this.close) return false;
      this.mouseleave();
      this.close = true;
      setTimeout(() => {
        this.close = false;
      }, 200);
    },
    mouseover(e) {
      this.show = false;
      let isexit = document.getElementById('pop');
      if (isexit) return false;
      let div = document.createElement('div');
      div.innerText = this.tips;
      div.id = 'pop';
      div.className = 'pop';
      div.style.top = `${e.target.getBoundingClientRect().top}px`;
      div.style.left = `${e.target.getBoundingClientRect().left}px`;
      div.style.transform = 'translate(-30%,-90%)';
      div.style.zIndex = '999999';
      document.body.appendChild(div);
    },
    mouseleave() {
      let isexit = document.getElementById('pop');
      if (isexit) {
        document.body.removeChild(isexit);
      }
    },
    emit() {
      let isexit = document.getElementById('pop');
      if (isexit) {
        document.body.removeChild(isexit);
      }
      if (this.disabled) return false
      // let accessId = window.localStorage.getItem('accessId');
      // let is_admin = this.$store.state.userInfo.is_admin;
      // if (accessId.includes(this.accessId) || is_admin == 1) {
      this.$emit('myclick');
      // } else {
      // 	this.$plugin.notifyError('暂无权限')
      // }
    }
  }

};
</script>

<style>
.popbox {
  margin-left: 15px;
  padding: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
  /* margin: 0 auto; */
}

.popitem {
  padding: 0;
  display: flex;
  align-items: center;
}

/* .icons{
		width: 14px;
		height: 14px;
		margin: 0;
		padding: 0;
	} */
.pop {
  height: 35px;
  /* width: 50px; */
  position: absolute;
  top: 80px;
  left: 100px;
  background: url(../assets/image/pop.png);
  background-size: 100% 35px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  font-size: 12px;
  padding: 0 5px;
  pointer-events: none;
}

.disabled {
  cursor: not-allowed;
}
</style>
