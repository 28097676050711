import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:{

    }
  },
  mutations: {
    setUserInfo(state,data){
      window.localStorage.setItem('userInfo',JSON.stringify(data));
      data.r_name = data.nickName;
      data.avatar = data.headerImg;
      state.userInfo = data;
     console.log(state.userInfo);
    }
  },
  actions: {
  },
  modules: {
  }
})
