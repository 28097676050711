<template>
  <div id="app">
			<MuyuNavigationModel v-if="show" :userInfo="$store.state.userInfo" @outLogin="outLogin"></MuyuNavigationModel>
    <router-view/>
  </div>
</template>
<script>
 export default{
   data(){
     return {
      show:false,
      userInfo:null
     }
   },
   created() {
			this.show = false;
		
		},
   watch:{
     '$route': {
				handler(to) {
					if (!to.name || to.name == 'logins') {
						this.show = false
					} else {
						this.show = true
					}
				},
				immediate: true
			}
   },
   methods:{
			outLogin(){
				window.localStorage.removeItem('userInfo');
				window.localStorage.removeItem('token');
				this.$router.replace('/')
			}
		}
 }
</script>
<style lang="scss" scoped>
	 /deep/ .el-pagination{
		 button{
			 width: 68px;
			 height: 28px;
			 background: #FFFFFF;
			 border: 1px solid #DEDEDE;
			 text-align: center;
			 line-height: 28px;
			 color: #333333;
			font-size: 12px;
		 }
	 }
   /deep/ .el-form-item__content{
     line-height: 32px;
   }
   // /deep/ .el-input{
   //   height: 32px;
   // }
/deep/ .el-input__inner{
  height: 32px;
  line-height: 32px;
  // vertical-align: top;
  border-radius: 0;
}
/deep/ .el-input__suffix{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/deep/ .el-range__icon,.el-range__icon,.el-icon-date{
  line-height: 10px;
  display: flex;
  align-items: center;
}
/deep/ .el-icon-circle-close{
   display: flex;
  align-items: center;
  line-height: 10px;
  color: red;
  &::before{
    line-height: 10px;
  }
  // background-color: red;
}

</style>
