<template>
  <div class="my-input" @mouseenter="mouseenter" @mouseleave="hover = false">
      <div class="my-input-title">
          <slot name="pre"></slot>
      </div>
      <div class="my-input-box" >
          <input type="text" @input="inputed" :value="value" :placeholder="placeholder">
      </div>
      <i class="el-icon-close" v-if="hover" @click="clear"></i>
	  <div class="search">
	     <slot name="search"></slot>
	  </div>
  </div>
</template>
<script>
export default {
    props: {
		'value':{
			type:String
		},
		'placeholder':{
			type:String
		},
	},
  data() {
    return {
      hover: false
    };
  },
  watch: {
    value: {
        handler(e) {
            e ? this.hover = true : this.hover = false;
        },
        immediate: true
    }
  },
  methods: {
      inputed(e) {
          this.$emit('input', e.target.value);
      },
      mouseenter() {
        this.value ? this.hover = true : this.hover = false;
      },
       mouseleave() {
       this.hover = false;
      },
      clear() {
           this.$emit('input', '');
        this.$emit('change', '');
        this.$emit('clear');
      }
  }
};
</script>
<style lang="scss">
 .my-input{
     width: 220px;
     height: 32px;
     font-size: 12px;
     border: 1px solid #E4E7ED;
     display: flex;
     align-items: center;
     padding: 0 10px;
     box-sizing: border-box;
     .my-input-box{
         flex: 1;
         height: 32px;
         padding: 0;
         input{
             width: 100%;
             height: 100%;
             padding-left: 10px;
             margin: 0;
             background: none;
            vertical-align: top;
            font-size: 12px;
            &::placeholder{
                font-size: 12px;
				color:#cbced4
            }
         }

     }
	 i{
		 cursor: pointer;
		 margin: 0 5px;
	 }
 }
</style>
