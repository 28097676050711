import axios from 'axios';
import {
  Notification
} from 'element-ui';

const request = (data = {}) => {
  return axios(data).then((res) => {
    if (res.status >= 400) {
      throw new Error('请求错误');
    } else {
      if(res.data.data.reload){
        window.location = window.location.origin + '/' + '#/login'
      }
      if (data.responseType == 'blob') {
        return res.data;
      }
      if (res.data.code == 0) {
        return res.data;
      }
      console.log(res)

      // if (res.data.reload == 7 && (res.data.msg == '无效token' || res.data.msg == '确少必要参数token')) {
      //   window.localStorage.setItem('userInfo', '');
      //   window.localStorage.setItem('token', '');
      // }
      // if (res.data.code == -1 && res.data.msg == '无效登陆！') {
      //   window.localStorage.setItem('userInfo', '');
      //   window.localStorage.setItem('token', '');
      // }

      Notification({
        title: '错误',
        message: res.data.msg || '未知错误',
        type: 'error'
      });
      throw new Error(res);
    }
  }).catch((error) => {
    throw new Error(error);
  });
};

export const HttpGet = async (path = '', data = {}, type = {}) => {
  const token = window.localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  };
  let succeed = await request({
    method: 'GET',
    url: process.env.VUE_APP_URL + path,
    headers,
    params: data,
    responseType: type.responseType || 'json'
  });
  return succeed;
};

export const HttpPost = async (path = '', data = {}, type = {}) => {
  const token = window.localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  };
  let url = '';
  if (path == '/refresh') {
    url = process.env.VUE_APP_PATH_URL;
  } else {
    url = process.env.VUE_APP_URL;
  }
  let succeed = await request({
    method: 'POST',
    url: url + path,
    headers,
    data,
    responseType: type.responseType || 'json'
  });
  return succeed;
};
