<template>
  <div class="navigationModel" @click="closeModel">
    <div class="nav">
      <div class="left">
        <div class="logo" @click.stop="isPanel = !isPanel">
          <img src="../assets/topnav/shrink.png" alt />
          <span>牧予科技</span>
        </div>
        <div class="content">
          <div class="centre">
            <img src="../assets/topnav/chanpian.png" alt />
            <span>产品中心</span>
          </div>
        </div>
      </div>
      <div class="user">
        <div class="message">
          <img src="../assets/topnav/msg.png" alt />
          消息
        </div>
        <div class="help">
          <img src="../assets/topnav/help.png" alt />
          帮助
        </div>
        <div class="navigationModelPersonalCenter" @click.stop="isUser = !isUser">
          <img
            v-if="userInfo && userInfo.headerImg"
            :src="userInfo.headerImg"
            @error="defaultSrc"
            class="headportrait"
            alt
          />
          <img v-else src="../assets/topnav/headportrait.png" alt />
          <img src="../assets/topnav/pullDown.png" class="pullDown" alt />
          <div class="personalCenterBox" v-if="isUser">
            <!-- <div class="marketName">
              渝北海领生鲜市场
            </div>-->
            <div class="list" @click.stop="information">
              <img src="../assets/topnav/user.png" alt />
              {{ userInfo && userInfo.nickName }}
            </div>
            <div class="list" @click.stop="out">
              <img src="../assets/topnav/out.png" alt />
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel" v-if="isPanel">
      <div class="panelBox" @click.stop="isPanel = true">
        <div :class="['list', act == '1' ? 'act' : '']" @click="act = 1">
          <img src="../assets/topnav/gailan.png" alt />
          概览
        </div>
        <div :class="['list', act == '2' ? 'act' : '']" @click="act = 2">
          <img src="../assets/topnav/shichang.png" alt />
          市场管理
        </div>
        <div :class="['list', act == '3' ? 'act' : '']" @click="act = 3">
          <img src="../assets/topnav/wuye.png" alt />
          物业管理
        </div>
        <div :class="['list', act == '4' ? 'act' : '']" @click="act = 4">
          <img src="../assets/topnav/renyuan.png" alt />
          人员管理
        </div>
        <div :class="['list', act == '5' ? 'act' : '']" @click="act = 5">
          <img src="../assets/topnav/xinfa.png" alt />
          信发管理
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import '.';

export default {
  name: 'MuyuNavigationModel',
  props: {
    // userInfo: {
    //   type: Object,
    //   default() {
    //     return null;
    //   }
    // }
  },
  data() {
    return {
      isUser: false,
      isPanel: false,
      act: 1,
      userInfo: null
    };
  },
  mounted() {
    let userInfo = window.localStorage.getItem('userInfo');
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo).user;
    }
  },
  methods: {
    closeModel() {
      this.isUser = false;
      this.isPanel = false;
    },
    out() {
      this.closeModel();
      this.$emit('outLogin');
    },
    information() {
      this.closeModel();
      this.$emit('information');
    },
    defaultSrc(event) {
      const img = event.srcElement;
      img.src = require('../assets/topnav/headportrait.png')
      img.onerror = null;
    }
  }
};
</script>

<style scoped lang="scss">
.navigationModel {
  .nav {
    width: 100%;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 138px;
        position: relative;
        &:after {
          content: " ";
          width: 1px;
          height: 26px;
          background: #dddddd;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        img {
          margin-left: 15px;
          width: 29px;
          height: 21px;
          vertical-align: middle;
          cursor: pointer;
        }
        span {
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold;
          font-weight: bold;
          color: #333333;
          margin-left: 20px;
        }
      }
      .content {
        .centre {
          width: 100px;
          height: 30px;
          line-height: 30px;
          background: #f2f3f3;
          border-radius: 2px;
          text-align: center;
          margin-left: 18px;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
            vertical-align: middle;
            margin-right: 9px;
          }
          span {
            font-size: 12px;
            color: #666666;
          }
        }
      }
    }
    .user {
      display: flex;
      font-size: 12px;
      color: #666666;
      align-items: center;
      margin-right: 140px;
      .message,
      .help {
        margin-left: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .navigationModelPersonalCenter {
        margin-left: 30px;
        cursor: pointer;
        position: relative;
        .headportrait {
          width: 28px;
          height: 28px;
        }
        .pullDown {
          width: 10px;
          height: 6px;
          margin-left: 5px;
        }
        .personalCenterBox {
          width: 220px;
          min-height: 98px;
          background: #ffffff;
          border: 1px solid #f1f1f2;
          box-shadow: 0px 2px 10px 0px rgba(101, 101, 101, 0.1);
          position: absolute;
          right: 0;
          top: 32px;
          padding-top: 5px;
          .marketName {
            font-size: 14px;
            color: #333333;
            height: 46px;
            line-height: 46px;
            padding-left: 30px;
            border-bottom: 1px solid #f1f1f2;
          }
          .list {
            font-size: 12px;
            color: #666666;
            height: 46px;
            line-height: 46px;
            padding-left: 30px;
            border-bottom: 1px solid #f1f1f2;
            display: flex;
            align-items: center;
            &:last-child {
              border-bottom: 0;
            }
            img {
              width: 12px;
              height: 12px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .panel {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 50px;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    .panelBox {
      width: 190px;
      height: 100%;
      background: #ffffff;
      position: fixed;
      z-index: 1001;
      top: 50px;
      left: 0;
      .list {
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        font-size: 12px;
        color: #666666;
        cursor: pointer;
        img {
          width: 13px;
          height: 13px;
          margin-right: 10px;
        }
        &.act {
          background: #f4f6f7;
          color: #333333;
        }
        &:hover {
          background: #f4f6f7;
          color: #333333;
        }
      }
    }
  }
}
</style>
